import React from 'react';

interface React9SliceProps {
  image: string;
  border?: number;
  width?: number;
  height?: number;
  children?: React.ReactNode;
  imageWidth: number;
  imageHeight: number;
  style?: React.CSSProperties;
}

const React9Slice: React.FC<React9SliceProps> = ({
  image,
  border = 8,
  width = 128,
  height = 128,
  children,
  imageWidth,
  imageHeight,
  style,
}) => {
  const OVERLAP = 1;

  // Create a wrapper for background slices only
  const SLICE_STYLE: React.CSSProperties = {
    display: 'block',
    backgroundImage: image ? `url(${image})` : undefined,
    imageRendering: 'pixelated',
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    willChange: 'transform',
    backfaceVisibility: 'hidden',
    position: 'absolute',
  };

  const IMAGE_X_SIZE_MINUS_BORDER = imageWidth - border * 2;
  const IMAGE_Y_SIZE_MINUS_BORDER = imageHeight - border * 2;
  
  const SCALE_X = width / IMAGE_X_SIZE_MINUS_BORDER;
  const SCALE_Y = height / IMAGE_Y_SIZE_MINUS_BORDER;

  return (
    <div 
      className="r9s" 
      style={{ 
        width: width + border * 2,
        height: height + border * 2,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Background slice container - handles overlaps */}
      <div 
        className="r9s-background" 
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: 'none',
        }}
      >
        {/* Top row */}
        <div className="r9s-nw" style={{
          ...SLICE_STYLE,
          width: border + OVERLAP,
          height: border + OVERLAP,
          top: 0,
          left: 0,
        }} />
        <div className="r9s-n" style={{
          ...SLICE_STYLE,
          width: IMAGE_X_SIZE_MINUS_BORDER + OVERLAP,
          height: border + OVERLAP,
          transformOrigin: 'left',
          transform: `scaleX(${SCALE_X})`,
          backgroundPositionX: -border,
          left: border,
          top: 0,
        }} />
        <div className="r9s-ne" style={{
          ...SLICE_STYLE,
          width: border + OVERLAP,
          height: border + OVERLAP,
          backgroundPositionX: -(imageWidth - border),
          left: width + border - OVERLAP,
          top: 0,
        }} />

        {/* Middle row */}
        <div className="r9s-w" style={{
          ...SLICE_STYLE,
          width: border + OVERLAP,
          height: IMAGE_Y_SIZE_MINUS_BORDER + OVERLAP,
          backgroundPositionY: -border,
          transformOrigin: 'top',
          transform: `scaleY(${SCALE_Y})`,
          left: 0,
          top: border,
        }} />
        <div className="r9s-c" style={{
          ...SLICE_STYLE,
          width: IMAGE_X_SIZE_MINUS_BORDER + OVERLAP,
          height: IMAGE_Y_SIZE_MINUS_BORDER + OVERLAP,
          transformOrigin: 'top left',
          transform: `scale(${SCALE_X}, ${SCALE_Y})`,
          backgroundPosition: `${-border}px ${-border}px`,
          left: border,
          top: border,
        }} />
        <div className="r9s-e" style={{
          ...SLICE_STYLE,
          width: border + OVERLAP,
          height: IMAGE_Y_SIZE_MINUS_BORDER + OVERLAP,
          backgroundPositionX: -(imageWidth - border),
          backgroundPositionY: -border,
          transformOrigin: 'top',
          transform: `scaleY(${SCALE_Y})`,
          left: width + border - OVERLAP,
          top: border,
        }} />

        {/* Bottom row */}
        <div className="r9s-sw" style={{
          ...SLICE_STYLE,
          width: border + OVERLAP,
          height: border + OVERLAP,
          backgroundPositionY: -(imageHeight - border),
          left: 0,
          top: height + border - OVERLAP,
        }} />
        <div className="r9s-s" style={{
          ...SLICE_STYLE,
          width: IMAGE_X_SIZE_MINUS_BORDER + OVERLAP,
          height: border + OVERLAP,
          transformOrigin: 'left',
          transform: `scaleX(${SCALE_X})`,
          backgroundPositionX: -border,
          backgroundPositionY: -(imageHeight - border),
          left: border,
          top: height + border - OVERLAP,
        }} />
        <div className="r9s-se" style={{
          ...SLICE_STYLE,
          width: border + OVERLAP,
          height: border + OVERLAP,
          backgroundPositionX: -(imageWidth - border),
          backgroundPositionY: -(imageHeight - border),
          left: width + border - OVERLAP,
          top: height + border - OVERLAP,
        }} />
      </div>

      {/* Content container - separate from background slices */}
      <div 
        className="r9s-content" 
        style={{
          position: 'absolute',
          top: border,
          left: border,
          width,
          height,
          zIndex: 1,
          ...style,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default React9Slice;