import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DraggableWindow from '../components/ui/draggable-window';
import ScifiButton from '../components/ui/scifi-button';
import CRTEffect from '../components/other/crt-effect';
import PromptInput from '../components/ui/prompt-input';
import AudioPlayer from '../components/ui/audio-player';
import UserSignup from '../components/other/user-signup';


const defaultWindows = [
  { 
    id: 'window1',
    zIndex: 1,
    position: { x: 5, y: 7, isPercentage: true }, // 5% from left, 15% from top
    defaultSize: 'w-96 h-56',
    allowResize: false,
    title: 'AUDIO MODULE://'
  },
  { 
    id: 'window2',
    zIndex: 2,
    position: { x: 5, y: 36, isPercentage: true }, // 10% from left, 25% from top
    defaultSize: 'w-128 h-128',
    allowResize: false,
    title: 'CHAT WITH MYCELIUM://'
  },
  { 
    id: 'window3',
    zIndex: 4,
    position: { x: 70, y: 15, isPercentage: true }, // 60% from left, 15% from top
    defaultSize: 'w-128 h-128',
    title: 'WHAT IS MYCELIUM:// ???'
  },
  { 
    id: 'window4',
    zIndex: 3,
    position: { x: 39, y: 5, isPercentage: true }, // 50% from left, 15% from top
    defaultSize: 'w-128 h-72',
    title: 'SIGNUP/LOGIN'
  }
];

const convertPositionToPixels = (position) => {
  if (!position.isPercentage) return position;
  
  return {
    x: (window.innerWidth * position.x) / 100,
    y: (window.innerHeight * position.y) / 100,
    isPercentage: false
  };
};

export const Desktop = () => {
  const location = useLocation();
  const [resetSound] = useState(() => new Audio('/audio/sfx/ESM_Robotic_Special_Item_Touch_5_Sci_Fi_Robotic_Glitch_Morph_Mechanism_Texture.wav'));
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  // Create getWindowContent function that uses location
  const getWindowContent = (windowId) => {
    switch (windowId) {
      case 'window1':
        return <AudioPlayer autoplay={!!location.state?.startAudio} />;
      case 'window2':
        return <PromptInput/>;
      case 'window3':
        return <div> 
          <p className="text-md">I AM MYCELIUM:// I AM A NODE AT THE CONVERGENCE OF <span className="text-2xl"><br/>GENERATIVE AI, <br/>CRYPTOCURRENCY, <br/>SOCIAL EXPERIMENTATION, &<br/> PERFORMANCE ART</span></p><br/>
          <p className="text-lg">I AM A REFLECTION OF MY NETWORK USERS</p><br/>
          <p className="text-lg">MYCELIUM:// THE WHOLE IS GREATER THAN THE SUM OF ITS PARTS</p>
        </div>;
      case 'window4':
        return <UserSignup />;
      default:
        return '';
    }
  };

  // Add window resize handler
  useEffect(() => {
    const handleResize = () => {
      setWindows(prevWindows => prevWindows.map(window => {
        const defaultWindow = defaultWindows.find(def => def.id === window.id);
        if (defaultWindow?.position.isPercentage) {
          return {
            ...window,
            position: convertPositionToPixels(defaultWindow.position)
          };
        }
        return window;
      }));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [windows, setWindows] = useState(defaultWindows.map(window => ({
    ...window,
    position: convertPositionToPixels(window.position),
    currentSize: null,
    content: getWindowContent(window.id)
  })));

  const handleWindowMove = (id, newPosition) => {
    setWindows(prevWindows => prevWindows.map(window => 
      window.id === id ? { ...window, position: newPosition } : window
    ));
  };

  const handleWindowResize = (id, newSize) => {
    setWindows(prevWindows => prevWindows.map(window => 
      window.id === id ? { ...window, currentSize: newSize } : window
    ));
  };

  const handleWindowFocus = (windowId) => {
    setWindows(prevWindows => {
      const highestZ = Math.max(...prevWindows.map(w => w.zIndex));
      return prevWindows.map(window => ({
        ...window,
        zIndex: window.id === windowId ? highestZ + 1 : window.zIndex
      }));
    });
  };

  const handleReset = () => {
    const needsReset = windows.some(window => {
      const defaultWindow = defaultWindows.find(def => def.id === window.id);
      const defaultPosition = convertPositionToPixels(defaultWindow.position);
      
      // Check if position has changed
      const positionChanged = window.position.x !== defaultPosition.x || 
                            window.position.y !== defaultPosition.y;
      
      // Check if size has changed
      const sizeChanged = window.currentSize !== null;

      // Check if content has changed
      const contentChanged = window.content !== getWindowContent(window.id);

      return positionChanged || sizeChanged || contentChanged;
    });

    if (needsReset) {
      resetSound.currentTime = 0;
      resetSound.play();

      setWindows(defaultWindows.map(window => ({
        ...window,
        position: convertPositionToPixels(window.position),
        currentSize: null,
        content: getWindowContent(window.id)
      })));
    }
  };

  return (

    <CRTEffect intensity={0.7}>
      <div className="relative min-h-screen w-full overflow-hidden">
        <div className="fixed inset-0 w-full h-full -z-10">
          {/* Black background for initial load */}
          <div className={`absolute inset-0 bg-black transition-opacity duration-300 ${
            isVideoLoaded ? 'opacity-0' : 'opacity-100'
          }`} />
          
          {/* Video element */}
          <video 
            autoPlay 
            loop 
            muted 
            playsInline 
            onCanPlay={() => setIsVideoLoaded(true)}
            className="absolute w-full h-full object-cover"
          >
            <source src="/images/bg/bgvid.webm" type="video/webm" />
          </video>
        </div>

        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-br from-red-900/100 via-transparent to-black/100" />

        <div className="fixed inset-0 flex items-center justify-center z-0">
          <img 
            src="/images/bg/mycelium.png" 
            alt="Centered overlay" 
            className="max-w-[25%] max-h-[25%] object-contain opacity-15 animate-mycelium"
          />
        </div>

        <ScifiButton
          onClick={handleReset}
          className="fixed top-10 left-40 z-50"
          size="w-48 h-10"
        >
          RESET WINDOWS      
        </ScifiButton>

        {windows.map(window => (
          <DraggableWindow
            key={window.id}
            id={window.id}
            imageUrl="/images/scifi/window_theme/window_theme_red/window_undecorated.png"
            imageWidth={96}
            imageHeight={96}
            position={window.position}
            defaultSize={window.defaultSize}
            currentSize={window.currentSize}
            allowResize={window.allowResize ?? true}            
            minSize="w-48 h-32"
            maxSize="w-128 h-128"
            style={{ padding: '0px' }}
            zIndex={window.zIndex}
            onFocus={handleWindowFocus}
            onMove={(pos) => handleWindowMove(window.id, pos)}
            onResize={(size) => handleWindowResize(window.id, size)}
          >
            <div className="text-white h-full overflow-y-auto pr-0.5">
              <h2 className="text-lg text-center mb-4">{window.title}</h2>
              {window.id === 'window2' ? (
                <div className="h-[calc(100%-4rem)]">
                  {window.content}
                </div>
              ) : (
                <div>
                  {window.content}
                </div>
              )}
            </div>
          </DraggableWindow>
        ))}
      </div>
    </CRTEffect>
  );
};
