import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { Desktop } from './pages/Desktop';
import { SolanaWalletProvider } from './components/other/solana-wallet-provider';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error:', error);
    console.error('Error Info:', errorInfo);
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: 20 }}>
          <h1>Something went wrong.</h1>
          <pre>{this.state.error && this.state.error.toString()}</pre>
          <pre>{this.state.errorInfo && this.state.errorInfo.componentStack}</pre>
        </div>
      );
    }

    return this.props.children;
  }
}

export const App = () => {
  return (
    <ErrorBoundary>
      <SolanaWalletProvider>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/desktop" element={<Desktop />} />
          </Routes>
        </Router>
      </SolanaWalletProvider>
    </ErrorBoundary>
  );
};
