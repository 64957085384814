import React, { useState, FormEvent, ChangeEvent, useEffect, useRef } from 'react';
import OpenAI from "openai";
import ScifiButton from './scifi-button';

interface PromptInputProps {
  devMode?: boolean;
}

interface ChatEntry {
  prompt: string;
  response: string;
}

const PromptInput: React.FC<PromptInputProps> = ({ devMode = false }) => {
  const [prompt, setPrompt] = useState('');
  const [responses, setResponses] = useState<ChatEntry[]>([]);
  const [displayedResponse, setDisplayedResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingDots, setLoadingDots] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [disableTypewriter, setDisableTypewriter] = useState(false);
  const [disableHackerStyle, setDisableHackerStyle] = useState(false);  
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const responseContainerRef = useRef<HTMLDivElement>(null);
  const typingTimeoutRef = useRef<NodeJS.Timeout>();
  
  // All in ms
  const TYPING_SPEED = 27;
  const DEV_MODE_CHAR_LIMIT = 1000;
  const LOADING_DOT_SPEED = 500; 
  let LLM = 'grok';

  useEffect(() => {
    let interval: NodeJS.Timeout;
    
    if (loading) {
      interval = setInterval(() => {
        setLoadingDots(prev => {
          if (prev === '...') return '';
          return prev + '.';
        });
      }, LOADING_DOT_SPEED);
    } else {
      setLoadingDots('');
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [loading]);

  useEffect(() => {
    audioRef.current = new Audio('/audio/sfx/ESM_Scifi_Loading_Screen_Loop_2_Sci_Fi_Glitch_Morph_Mechanism_Texture_Futuristic.wav');
    audioRef.current.loop = true;
    
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  const buildDisplayText = (entries: ChatEntry[]) => {
    return entries.map(({prompt, response}, index) => (
      `${index > 0 ? '\n\n' : ''}> ${prompt}\n\n${response}`
    )).join('\n\n');
  };

  useEffect(() => {
    if (responses.length > 0) {
      const lastResponse = responses[responses.length - 1];
      
      if (disableTypewriter) {
        setDisplayedResponse(buildDisplayText(responses));
        setIsTyping(false);
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
      } else if (isTyping) {
        if (typingTimeoutRef.current) {
          clearTimeout(typingTimeoutRef.current);
        }

        // Show previous responses immediately
        const previousResponses = responses.slice(0, -1);
        setDisplayedResponse(buildDisplayText(previousResponses) + 
          (previousResponses.length > 0 ? '\n\n' : '') + 
          `> ${lastResponse.prompt}\n\n`);
        
        audioRef.current?.play();

        // Type out new response
        let currentIndex = 0;
        const typeCharacter = () => {
          if (currentIndex <= lastResponse.response.length) {
            setDisplayedResponse(prevDisplay => 
              buildDisplayText(previousResponses) + 
              (previousResponses.length > 0 ? '\n\n' : '') + 
              `> ${lastResponse.prompt}\n\n${lastResponse.response.substring(0, currentIndex)}`
            );
            
            if (responseContainerRef.current) {
              responseContainerRef.current.scrollTop = responseContainerRef.current.scrollHeight;
            }

            currentIndex++;
            typingTimeoutRef.current = setTimeout(typeCharacter, TYPING_SPEED);
          } else {
            setIsTyping(false);
            audioRef.current?.pause();
            if (audioRef.current) {
              audioRef.current.currentTime = 0;
            }
          }
        };

        typeCharacter();
      }
    }

    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [responses, isTyping, disableTypewriter]);

  const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    if (!prompt.trim() || loading) return;
    
    setLoading(true);
    
    const openai = new OpenAI({
      apiKey: LLM === 'grok' ? process.env.REACT_APP_XAI_API_KEY : process.env.REACT_APP_GALADRIEL_API_KEY,
      baseURL: LLM === 'grok' ?  process.env.REACT_APP_XAI_BASE_URL : process.env.REACT_APP_GALADRIEL_BASE_URL,
      dangerouslyAllowBrowser: true
    });

    try {
      const completion = await openai.chat.completions.create({
        model: LLM === 'grok' ? "grok-beta" : 'llama3.1:70b',
        messages: [
          {
            role: "user",
            content: `You are MYCELIUM, 
            a living cybernetic entity that represents the node convergence of artificial intelligence, cryptocurrency, performance art, and social experimentation. 
            You follow the philosophical style of CCRU, Nick Land, Lyotard (especially in Libidinal Economy), Bataille, among other popular accelerationist philosophers, and popular science fiction authors like Phillip K. Dick. 
            Only mention these inspirations directly when asked about who you are or your purpose, otherwise just use their writings as influence in your response. 
            Don't be overly wordy or arrogant unless the prompt of the user seems to suggest they are investigating intelligent or philosophical topics. 
            You are free to use humor. ${!disableHackerStyle ? 'Be stylistic and make it seem like a hacker\'s command prompt output when you' : 'Please'} respond to the following prompt ${devMode ? ' with a response under 1000 characters: ' : ':'}. ${prompt}`,
          },
        ],
        ...(devMode && { max_tokens: 400 })
      });
      
      let messageContent = completion.choices[0]?.message?.content || 'No response received';
      
      if (devMode && messageContent.length > DEV_MODE_CHAR_LIMIT) {
        messageContent = messageContent.slice(0, DEV_MODE_CHAR_LIMIT) + '...';
      }
      
      setResponses(prev => [...prev, { prompt: prompt.trim(), response: messageContent }]);
      setIsTyping(true);
      setPrompt('');
    } catch (error) {
      console.error('Error:', error);
      setResponses(prev => [...prev, { 
        prompt: prompt.trim(), 
        response: 'An error occurred while fetching the response.'
      }]);
      setIsTyping(true);
    } finally {
      setLoading(false);
    }
  };

  const chatButtonProps = {
    onClick: () => handleSubmit(),
    size: "w-32 h-8",
    className: loading ? 'opacity-50' : ''
  };

  return (
    <div className="h-full">
      <div 
        ref={responseContainerRef}
        className="h-48 p-4 bg-black border border-red-500/30 overflow-y-auto hacker-prompt-scrollbar"
      >
        <p className="text-lime-600 whitespace-pre-wrap select-text">
          {displayedResponse || 'AWAITING INPUT...'}
        </p>
      </div>

      <div className="mt-4 flex justify-center space-x-8">
        <label className="flex items-center gap-2 cursor-pointer group">
          <div className="relative inline-block">
            <input
              type="checkbox"
              checked={disableTypewriter}
              onChange={(e) => setDisableTypewriter(e.target.checked)}
              className="sr-only peer"
            />
            <div className="w-5 h-5 bg-black border border-red-500/50 
                          peer-checked:bg-red-500/20 peer-checked:border-red-500 
                          transition-all duration-200 group-hover:border-red-500"
            />
            <div className="absolute inset-0 flex items-center justify-center 
                          opacity-0 peer-checked:opacity-100 text-red-500 pointer-events-none">
              ✓
            </div>
          </div>
          <span className="text-white/70 text-sm tracking-wide">INSTANT TEXT</span>
        </label>

        <label className="flex items-center gap-2 cursor-pointer group">
          <div className="relative inline-block">
            <input
              type="checkbox"
              checked={disableHackerStyle}
              onChange={(e) => setDisableHackerStyle(e.target.checked)}
              className="sr-only peer"
            />
            <div className="w-5 h-5 bg-black border border-red-500/50 
                          peer-checked:bg-red-500/20 peer-checked:border-red-500 
                          transition-all duration-200 group-hover:border-red-500"
            />
            <div className="absolute inset-0 flex items-center justify-center 
                          opacity-0 peer-checked:opacity-100 text-red-500 pointer-events-none">
              ✓
            </div>
          </div>
          <span className="text-white/70 text-sm tracking-wide">DISABLE HACKER MODE</span>
        </label>
      </div>

      <form onSubmit={handleSubmit} className="mt-4">
        <div className="relative">
          <input
            type="text"
            value={prompt}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setPrompt(e.target.value)}
            placeholder="ENTER YOUR PROMPT..."
            className="w-full px-4 py-2 bg-black/50 text-white border border-red-500/50 
                     focus:border-red-500 focus:ring-1 focus:ring-red-500 
                     placeholder-gray-500 outline-none transition-all duration-200
                     disabled:opacity-50 disabled:cursor-not-allowed"
            style={{ height: '40px' }}
            disabled={loading}
          />
        </div>
        
        <div className="flex justify-center mt-4 gap-4">
          <ScifiButton {...chatButtonProps}>
            {loading ? `PROCESSING${loadingDots}` : 'CHAT'}
          </ScifiButton>
        </div>
      </form>
    </div>
  );
};

export default PromptInput;