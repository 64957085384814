import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { SolanaConnect } from './solana-connect';
import { useWallet } from '@solana/wallet-adapter-react';
import { apiClient, UserResponse, HealthResponse } from '../../api/client';
import ScifiButton from '../ui/scifi-button';

interface ValidationError {
  message: string;
  type: 'warning' | 'error';
}

const USERNAME_RULES = {
  minLength: 3,
  maxLength: 15,
  pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/,
};

const UserSignup: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [username, setUsername] = useState<string | null>(null);
  const [inputUsername, setInputUsername] = useState<string>('');
  const [isEligibleForSignup, setIsEligibleForSignup] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<ValidationError | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loadingDots, setLoadingDots] = useState<string>('');
  
  const { publicKey, connected } = useWallet();

  // Reset states when wallet is disconnected
  useEffect(() => {
    if (!connected) {
      setUsername(null);
      setIsEligibleForSignup(false);
      setError(null);
    }
  }, [connected]);

  // Username validation
  const validateUsername = (input: string): ValidationError | null => {
    if (input.length === 0) return null;
    
    if (input.length < USERNAME_RULES.minLength) {
      return {
        message: `Username must be at least ${USERNAME_RULES.minLength} characters`,
        type: 'warning'
      };
    }
    
    if (input.length > USERNAME_RULES.maxLength) {
      return {
        message: `Username must be no more than ${USERNAME_RULES.maxLength} characters`,
        type: 'error'
      };
    }

    if (!USERNAME_RULES.pattern.test(input)) {
      return {
        message: 'Username must start with a letter and can only contain letters, numbers, and underscores',
        type: 'error'
      };
    }

    return null;
  };

  // Handle username input
  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputUsername(value);
    setValidationError(validateUsername(value));
  };

  // Check username when wallet is connected
  const checkUsername = async (walletAddress: string): Promise<void> => {
    if (!walletAddress) return;

    try {
      setLoading(true);
      const data = await apiClient.get<UserResponse>(`/api/users/wallet/${walletAddress}`);
      
      if (data.username) {
        setUsername(data.username);
        setIsEligibleForSignup(false);
      } else {
        setUsername(null);
        setIsEligibleForSignup(true);
      }
    } catch (err) {
      console.error('Username check error:', err);
      setError(err instanceof Error ? err.message : 'Failed to check username');
      setIsEligibleForSignup(false);
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (e?: FormEvent<HTMLFormElement>): Promise<void> => {
    if (e) e.preventDefault();
    
    if (!publicKey || !inputUsername || validationError) return;
    
    try {
      setSubmitting(true);
      setError(null);
      
      // Create the user
      const response = await apiClient.post<UserResponse>('/api/users', {
        username: inputUsername,
        walletAddress: publicKey.toString()
      });
      
      if (response.username) {
        setUsername(response.username);
        setIsEligibleForSignup(false);
        setInputUsername('');
      } else {
        throw new Error('Failed to create user');
      }
      
    } catch (err) {
      console.error('Signup error:', err);
      if (err instanceof Error && err.message.includes('409')) {
        setValidationError({
          message: 'Username is already taken',
          type: 'error'
        });
      } else {
        setError(err instanceof Error ? err.message : 'Failed to create username');
      }
    } finally {
      setSubmitting(false);
    }
  };

  // Loading dots animation
  useEffect(() => {
    if (submitting) {
      const interval = setInterval(() => {
        setLoadingDots(dots => dots.length >= 3 ? '' : dots + '.');
      }, 500);
      return () => clearInterval(interval);
    } else {
      setLoadingDots('');
    }
  }, [submitting]);

  // Check username when wallet connection changes
  useEffect(() => {
    if (connected && publicKey) {
      checkUsername(publicKey.toString());
    }
  }, [connected, publicKey]);

  // Initial health check
  useEffect(() => {
    async function checkHealth(): Promise<void> {
      try {
        await apiClient.get<HealthResponse>('/health');
      } catch (err) {
        console.error('Health check failed:', err);
        setError('API connection failed - please check CORS configuration');
      } finally {
        setLoading(false);
      }
    }

    checkHealth();
  }, []);

  const submitButtonProps = {
    onClick: () => handleSubmit(),
    size: "w-32 h-8",
    className: loading ? 'opacity-50' : ''
  };

  return (
    <div>
      <div className="flex justify-center">
        <SolanaConnect />
      </div>
            
      {loading && <div>Loading...</div>}
      {error && (
        <div className="text-red-500 p-4 rounded bg-red-50">
          Error: {error}
        </div>
      )}
      
      {connected && username && (
        <div className="mt-4 text-xl flex">
          WELCOME BACK, @{username}
        </div>
      )}
      {isEligibleForSignup && (
        <div className="mt-4">
          <p>You're eligible to sign up for a username:</p>
          <form onSubmit={handleSubmit} className="mt-4">
            <div className="relative">
              <input
                type="text"
                value={inputUsername}
                onChange={handleUsernameChange}
                placeholder="ENTER A USERNAME..."
                className={`w-full px-4 py-2 bg-black/50 text-white border 
                         ${validationError?.type === 'error' ? 'border-red-500' : 'border-red-500/50'}
                         focus:border-red-500 focus:ring-1 focus:ring-red-500 
                         placeholder-gray-500 outline-none transition-all duration-200
                         disabled:opacity-50 disabled:cursor-not-allowed`}
                style={{ height: '40px' }}
                disabled={submitting}
                maxLength={USERNAME_RULES.maxLength}
              />
              {validationError && (
                <div className={`mt-1 text-sm ${
                  validationError.type === 'error' ? 'text-red-500' : 'text-yellow-500'
                }`}>
                  {validationError.message}
                </div>
              )}
              <div className="mt-1 text-sm text-gray-400">
                Username must be {USERNAME_RULES.minLength}-{USERNAME_RULES.maxLength} characters, 
                start with a letter, and contain only letters, numbers, and underscores.
              </div>
            </div>
            
            <div className="flex justify-center mt-4 gap-4">
              <ScifiButton {...submitButtonProps}>
                {submitting ? `PROCESSING${loadingDots}` : 'SUBMIT'}
              </ScifiButton>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default UserSignup;