export interface ApiResponse<T = any> {
    data?: T;
    error?: string;
    status?: number;
  }
  
  export interface HealthResponse {
    status: string;
    timestamp: string;
    origin: string;
  }
  
  export interface UserResponse {
    username: string | null;
    walletAddress?: string;
    error?: string;
  }
  
  // Error type
  export class ApiError extends Error {
    status?: number;
    statusText?: string;
    
    constructor(message: string, status?: number, statusText?: string) {
      super(message);
      this.name = 'ApiError';
      this.status = status;
      this.statusText = statusText;
    }
  }
  
  // API URL configuration
  const API_URL = process.env.REACT_APP_API_URL?.startsWith('http')
    ? process.env.REACT_APP_API_URL
    : `https://${process.env.REACT_APP_API_URL}`;
  
  // Response handler with generic type
  const handleResponse = async <T>(response: Response): Promise<T> => {
    if (!response.ok) {
      let errorMessage: string;
      try {
        const errorData = await response.json();
        errorMessage = errorData.error || errorData.message;
      } catch {
        errorMessage = await response.text();
      }
  
      throw new ApiError(
        errorMessage || `HTTP error! status: ${response.status}`,
        response.status,
        response.statusText
      );
    }
  
    // Check if response is empty
    const text = await response.text();
    if (!text) {
      return null as T;
    }
  
    // Parse JSON response
    try {
      return JSON.parse(text) as T;
    } catch (error) {
      console.error('Invalid JSON response:', text);
      throw new ApiError('Invalid JSON response received');
    }
  };
  
  // Common fetch options
  const commonFetchOptions: RequestInit = {
    mode: 'cors',
    credentials: 'include',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };
  
  // API client with typed methods
  export const apiClient = {
    async get<T>(endpoint: string): Promise<T> {
      try {
        const url = `${API_URL}${endpoint}`;
        const response = await fetch(url, {
          ...commonFetchOptions,
          method: 'GET'
        });
        return await handleResponse<T>(response);
      } catch (error) {
        if (error instanceof Error && 
           (error.message.includes('NetworkError') || 
            error.message.includes('Failed to fetch'))) {
          throw new ApiError('Network error - Please check your connection or CORS configuration');
        }
        throw error;
      }
    },
  
    async post<T>(endpoint: string, data: unknown): Promise<T> {
      try {
        const url = `${API_URL}${endpoint}`;
        const response = await fetch(url, {
          ...commonFetchOptions,
          method: 'POST',
          body: JSON.stringify(data)
        });
        return await handleResponse<T>(response);
      } catch (error) {
        if (error instanceof Error && 
           (error.message.includes('NetworkError') || 
            error.message.includes('Failed to fetch'))) {
          throw new ApiError('Network error - Please check your connection or CORS configuration');
        }
        throw error;
      }
    },
  
    async put<T>(endpoint: string, data: unknown): Promise<T> {
      try {
        const url = `${API_URL}${endpoint}`;
        const response = await fetch(url, {
          ...commonFetchOptions,
          method: 'PUT',
          body: JSON.stringify(data)
        });
        return await handleResponse<T>(response);
      } catch (error) {
        if (error instanceof Error &&
           (error.message.includes('NetworkError') ||
            error.message.includes('Failed to fetch'))) {
          throw new ApiError('Network error - Please check your connection or CORS configuration');
        }
        throw error;
      }
    },
  
    async delete<T>(endpoint: string): Promise<T> {
      try {
        const url = `${API_URL}${endpoint}`;
        const response = await fetch(url, {
          ...commonFetchOptions,
          method: 'DELETE'
        });
        return await handleResponse<T>(response);
      } catch (error) {
        if (error instanceof Error &&
           (error.message.includes('NetworkError') ||
            error.message.includes('Failed to fetch'))) {
          throw new ApiError('Network error - Please check your connection or CORS configuration');
        }
        throw error;
      }
    }
  };